import { SelectiveLoadingStrategy } from './selective-loading-strategy';
import { AppModule } from 'src/app/app.module';

export function PreLoad(page: string): ClassDecorator {
  return function(constructor: any) {
    const loader = AppModule.injector.get(SelectiveLoadingStrategy);

    const ngOnInit = constructor.prototype.ngOnInit;

    constructor.prototype.ngOnInit = function(...args) {
      loader.preLoadRoute(page);
      if (ngOnInit) {
        ngOnInit.apply(this, args);
      }
    };
  };
}
